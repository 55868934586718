export enum RoutePathEnum {
  ROOT = '/',

  ACCOUNT_DEACTIVATED = '/account-deactivated',

  DASHBOARD = '/dashboard',

  EKYC_REQUIRED = '/profile/ekyc', // '/ekyc-required',
  ERROR = '/error',

  FEEDBACK = '/feedback',

  INBOX = '/inbox',
  ISAF = '/ISAF',

  LOGIN_CALLBACK = '/login/callback',

  MARKET_INSIGHT = '/market-insight',

  ORDER_HISTORY = '/order/history',
  ORDER_PURCHASE = '/order/purchase',
  ORDER_PURCHASE_FORM = '/order/purchase/form',
  ORDER_PURCHASE_INVESTED_FUNDS = '/order/purchase/invested-funds',

  ORDER_REDEMPTION = '/order/redemption',
  ORDER_REDEMPTION_FORM = '/order/redemption/form',
  ORDER_REDEMPTION_RECEIPT = '/order/redemption/receipt',

  ORDER_SWITCHING = '/order/switching',
  ORDER_SWITCHING_FORM = '/order/switching/form',
  ORDER_SWITCHING_RECEIPT = '/order/switching/receipt',

  PORTFOLIO = '/portfolio',

  PROFILE_ECDD = '/profile/ecdd',
  PROFILE_EDIT = '/profile/edit',
  PROFILE_SETUP = '/profile/setup',
  PROFILE_VERIFICATION = '/profile/verification',

  RECOVER_USER_ID = '/recover-user-id',
  RESET_PASSWORD = '/reset-password',

  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
}
