import { CustomerProfileAddressContact } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CustomerProfileTypeEnum } from '@/api/customerApi'
import { useQueryGetPofileData } from '@/api/onboardingApi'
import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import { filterByLov, formatAddress } from '@/utils'
import useProfileForm, { ProfileInfoPageEnum } from './hooks/useProfileForm'
import useProfileType from './hooks/useProfileType'
import ContactInfoForm from './subpages/ContactInfoForm'
import EmploymentInfoForm from './subpages/EmploymentInfoForm'
import { BooleanFormValues } from '@/enums/formValueEnum'
import { useQueryPostalList } from '@/api/commonApi'

const ProfileInfo = () => {
  const navigate = useNavigate()
  const profileType = useProfileType()

  const {
    lovsCurrLang,
    lovValidated,
    formMethods,
    currentPage,
    responseErrorAttrs,
    isLoading: formIsLoading,
    onClickNext,
    onClickBack,
    onSubmit,
  } = useProfileForm(profileType?.type)
  const { setValue } = formMethods

  const {
    isLoading: queryIsLoading,
    status: queryStatus,
    data: queryData,
    error: queryError,
  } = useQueryGetPofileData(profileType)

  const { isLoading: isLoadingPostalQuery, data: postalList } =
    useQueryPostalList()

  useEffect(() => {
    if (queryStatus === 'error') {
      console.log('error:', queryError)
      navigate(
        profileType?.type === CustomerProfileTypeEnum.EKYC
          ? RoutePathEnum.EKYC_REQUIRED
          : RoutePathEnum.ERROR
      )
      return
    }
    if (queryStatus === 'success' && !!queryData) {
      const {
        identities,
        primaryContact,
        epfDetails,
        crsCountries,
        correspondentContact,
      } = queryData

      //all types --
      setValue('fullName', queryData.fullName || '')
      setValue(
        'idNo',
        !!identities && !!identities.length && identities[0]?.idNo
          ? identities[0].idNo
          : ''
      )
      setValue('email', queryData.email || '')
      setValue('mobilePhoneNo', queryData.mobilePhoneNo || '')
      if (!!queryData?.gender && !!lovsCurrLang?.gender) {
        const _gender = lovsCurrLang.gender.find(
          ({ value }) => value === queryData.gender
        )
        setValue('gender', _gender?.value || '')
      }
      if (!!queryData?.nationality && !!lovsCurrLang?.country) {
        const _country = filterByLov(
          queryData.nationality,
          lovsCurrLang.country
        )
        setValue('nationality', _country?.label || '')
      }
      if (!!primaryContact) {
        const _pc = formatAddress({
          address: primaryContact,
          addressLineNum: 5,
        }) as CustomerProfileAddressContact

        setValue(
          'primaryContact.addressLines.addressLine1',
          _pc.addressLine1 || ''
        )
        setValue(
          'primaryContact.addressLines.addressLine2',
          _pc?.addressLine2 || ''
        )
        setValue(
          'primaryContact.addressLines.addressLine3',
          _pc?.addressLine3 || ''
        )
        setValue(
          'primaryContact.addressLines.addressLine4',
          _pc?.addressLine4 || ''
        )
        setValue(
          'primaryContact.addressLines.addressLine5',
          _pc?.addressLine5 || ''
        )
        setValue('primaryContact.postalInfo.cityName', _pc?.cityName || '')
        if (!!_pc?.country && !!lovsCurrLang?.country) {
          const _country = filterByLov(_pc.country, lovsCurrLang.country)
          setValue('primaryContact.postalInfo.country', _country?.value || '')
        }
        setValue('primaryContact.postalInfo.postalCode', _pc?.postalCode || '')
        if (!!_pc?.state && !!lovsCurrLang?.state) {
          const _state = filterByLov(_pc.state, lovsCurrLang.state)
          setValue('primaryContact.postalInfo.state', _state?.value || '')
        }
      }

      //epf type --
      if (profileType?.type === 'epf') {
        setValue(
          'epfAccountType',
          epfDetails?.epfiStatus === undefined
            ? ''
            : !!epfDetails.epfiStatus
            ? 'Islamic'
            : 'Conventional'
        )
        setValue('epfNumber', epfDetails?.epfNumber || '')
        setValue(
          'epfiStatusEffectiveDate',
          epfDetails?.epfiStatusEffectiveDate || ''
        )
        return
      }

      //existing type --
      if (profileType?.type === 'existing') {
        setValue('maritalStatus', queryData.maritalStatus || '')
        if (!!queryData?.race && !!lovsCurrLang?.race) {
          const _race = filterByLov(queryData.race, lovsCurrLang.race)
          setValue('race', _race?.value || '')
        }
        if (!!queryData?.occupation && !!lovsCurrLang?.occupation) {
          const _occupation = filterByLov(
            queryData.occupation,
            lovsCurrLang.occupation
          )
          setValue('occupation', _occupation?.value || '')
        }
        setValue('natureOfBusiness', queryData.natureOfBusiness || '')
        setValue('yearlyIncomeLevel', queryData.yearlyIncomeLevel || '')
        setValue('employerName', queryData.employerName || '')
        setValue('sourceOfFund', queryData.sourceOfFund || '')
        setValue('investmentObjectives', queryData.investmentObjectives || '')
        if (queryData.pepFlag !== undefined) {
          setValue(
            'pepFlag',
            queryData.pepFlag ? BooleanFormValues.TRUE : BooleanFormValues.FALSE
          )
        }
        const isTROM = !!crsCountries && !!crsCountries[0]
        setValue(
          'taxResidentOtherThanMalaysia',
          isTROM ? BooleanFormValues.TRUE : BooleanFormValues.FALSE
        )
        const _crsCountries = isTROM
          ? [
              {
                country: crsCountries[0].country,
                tin: crsCountries[0].tin,
              },
            ]
          : []
        setValue('crsCountries', _crsCountries)

        if (!!correspondentContact) {
          setValue('isPrimaryContactCurrent', false)

          const _cc = formatAddress({
            address: correspondentContact,
            addressLineNum: 5,
          }) as CustomerProfileAddressContact

          setValue(
            'correspondentContact.addressLines.addressLine1',
            _cc.addressLine1 || ''
          )
          setValue(
            'correspondentContact.addressLines.addressLine2',
            _cc?.addressLine2 || ''
          )
          setValue(
            'correspondentContact.addressLines.addressLine3',
            _cc?.addressLine3 || ''
          )
          setValue(
            'correspondentContact.addressLines.addressLine4',
            _cc?.addressLine4 || ''
          )
          setValue(
            'correspondentContact.addressLines.addressLine5',
            _cc?.addressLine5 || ''
          )
          setValue(
            'correspondentContact.postalInfo.cityName',
            _cc?.cityName || ''
          )
          if (!!_cc?.country && !!lovsCurrLang?.country) {
            const _country = filterByLov(_cc.country, lovsCurrLang.country)
            setValue(
              'correspondentContact.postalInfo.country',
              _country?.value || ''
            )
          }
          setValue(
            'correspondentContact.postalInfo.postalCode',
            _cc?.postalCode || ''
          )
          if (!!_cc?.state && !!lovsCurrLang?.state) {
            const _state = filterByLov(_cc.state, lovsCurrLang.state)
            setValue(
              'correspondentContact.postalInfo.state',
              _state?.value || ''
            )
          }
        }
      }
    }
  }, [queryStatus, queryData, queryError, lovValidated])

  return !lovValidated ||
    !!queryIsLoading ||
    !!isLoadingPostalQuery ||
    !lovsCurrLang ? (
    <Loading isLoadingPage={true} />
  ) : (
    <FormProvider {...formMethods}>
      {currentPage === ProfileInfoPageEnum.EMPLOYMENT_INFO ? (
        <EmploymentInfoForm
          onNavBack={onClickBack}
          onSubmitForm={onSubmit}
          isSubmitting={formIsLoading}
          responseErrorAttrs={responseErrorAttrs}
          lovs={lovsCurrLang}
        />
      ) : (
        <ContactInfoForm
          onSubmitForm={onClickNext}
          isSubmitting={formIsLoading}
          lovs={lovsCurrLang}
          postalList={postalList || []}
        />
      )}
    </FormProvider>
  )
}

export default ProfileInfo
