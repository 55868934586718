import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import PostLogonContainer from '@/layout/PostLogonContainer'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import { Button } from '@mui/material'
import { FormLabel } from '@pimy-b2cweb/frontend-lib'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextArea from './components/TextArea'

export interface FeedbackFormProps {
  message: string
}

const Feedback = () => {
  const { t } = useTranslation(['feedbackPage', 'common'])
  const { control, handleSubmit } = useForm<FeedbackFormProps>({
    defaultValues: {
      message: '',
    },
  })
  const onSubmit = (data: FeedbackFormProps) => {
    console.log(data)
    // TODO: API call to submit feedback
  }
  return (
    <>
      <HelmetSiteTitle pageTitle={t('feedback')} />

      <PostLogonMainBody>
        <PostLogonContainer className='!bg-transparent sm:!bg-white'>
          <h1 className='font-bold'>{t('feedback')}</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='message'
              control={control}
              defaultValue=''
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormLabel id='message' label={t('message')} isError={!!error}>
                  <TextArea
                    {...field}
                    minRows={10}
                    maxRows={10}
                    placeholder={t('please-describe-problem')}
                    className='w-full resize-none'
                    error={error}
                  />
                </FormLabel>
              )}
            />
            <Button
              fullWidth
              variant='contained'
              size='large'
              className='mb-6 mt-10'
              type='submit'
            >
              {t('Submit')}
            </Button>
          </form>

          <p className='mb-0 text-center text-pi-gray-1'>
            {t('chat-with-our-live-agent', { ns: 'common' })}
          </p>
        </PostLogonContainer>
      </PostLogonMainBody>
    </>
  )
}

export default Feedback
