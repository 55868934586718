import { TextareaAutosizeProps, TextareaAutosize } from '@mui/material'
import { FormErrorMessage } from '@pimy-b2cweb/frontend-lib'
import { forwardRef, memo } from 'react'
import { FieldError } from 'react-hook-form'

export interface TextAreaProps extends TextareaAutosizeProps {
  error?: FieldError
  className?: string
}
export const TextArea = memo(
  forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({ className = '', error, ...rest }, ref) => (
      <div className={`form-group-comp mt-4 ${className}`}>
        <TextareaAutosize
          ref={ref}
          {...rest}
          className={`border rounded border-solid border-pi-gray-1 px-4 py-3 !text-base font-sans ${
            !!error ? '!border-pi-utility-red' : ''
          } ${className}`}
        />
        <FormErrorMessage error={error} />
      </div>
    )
  )
)

export default TextArea
