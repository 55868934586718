import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  useResponseError,
  RadioGroupComp,
  CSLink,
} from '@pimy-b2cweb/frontend-lib'
import { useDispatch } from 'react-redux'
import { sessionResult } from '@/stores/isaf'
import {
  RiskProfileQuestionnaireContextDto,
  RiskProfileQuestionnaireSubmitReqDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useMutationSubmitQuestionnaire } from '@/api/riskProfileApi'

export interface QuestionnaireFormProps {
  [key: string]: string
}

export const Questionnaire = ({
  goNext,
  questionnaire,
  showVcNote = false,
}: {
  goNext: () => void
  questionnaire?: RiskProfileQuestionnaireContextDto
  showVcNote?: boolean
}) => {
  const { t } = useTranslation(['isafPage', 'common'])
  const dispatch = useDispatch()

  const { control, handleSubmit } = useForm<QuestionnaireFormProps>({
    mode: 'onChange',
  })
  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSubmitQuestionnaire()

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const onSubmit = async (data: QuestionnaireFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    const mutatingData: RiskProfileQuestionnaireSubmitReqDto = {
      questionGroup: questionnaire?.questionGroup.toString() as string,
      answers: Object.entries(data).map(([questionCode, optionCode]) => {
        return { questionCode, optionCode }
      }),
    }
    mutate(mutatingData)
  }
  useEffect(() => {
    if (mutateStatus === 'error') {
      setResponseErrorAttrs({
        i18nKey: (mutatedError as Error)?.message,
      })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(
        sessionResult({
          result: mutatedData.riskLevel,
        })
      )
      goNext()
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1 className='leading-7 mb-6'>{t('what-type-investor')}</h1>
      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {questionnaire?.questions?.map(({ code, options, text }) => (
          <Controller
            key={code}
            name={code}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
            }}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormLabel
                id={code}
                label={
                  <span className='whitespace-normal text-sm'>{text}</span>
                }
                isError={!!error}
                className='mb-6'
              >
                <RadioGroupComp
                  {...field}
                  items={options.map(({ code, text }) => {
                    return { label: text, key: code, value: code }
                  })}
                  error={error}
                  disabled={mutateIsLoading}
                />
              </FormLabel>
            )}
          />
        ))}

        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
      {!!showVcNote && (
        <p className='text-xs text-pi-gray-2 mt-6 mb-0'>
          <Trans
            i18nKey='vulnerable-client-note'
            t={t}
            components={{
              CS: <CSLink url='https://www.principal.com.my/vc' />,
            }}
          />
        </p>
      )}
    </>
  )
}

export default Questionnaire
